import React, {useState, useEffect} from 'react'

import {config, ServerUrl, Token, Code, ImagesUrl } from './include';
import axios from 'axios'
import Cookies from 'js-cookie'

import { useDispatch } from 'react-redux';
const Topbar = (props:{title?:string})=> {

  const dispatch = useDispatch()

const fetchContent = async()=>{
    if(Cookies.get('vlifeinvcdad')){
    var sql ="Select username, email, code from tbl_admin where code ='"+Code+"'";
    const fd = new FormData();
    fd.append("sql", sql);
    fd.append('jwt', Token);
    let url = ServerUrl+'/fetchBySql_controller.php'
    const {data} = await axios.post(url, fd, config)
     dispatch({
      type:'SET_RECORDS',
      name:'user',
      data:data[0]
  })
    }else{
      window.location.href='/?refferer='+window.location.href
    }
}



useEffect(()=>{
   fetchContent() 
    },[]);

    return (
      <nav
      id="main-navbar"
      className="navbar navbar-expand-lg navbar-light bg-white "
      >
      <div className="container-fluid">
      <button
             className="navbar-toggler"
             type="button"
             data-mdb-toggle="collapse"
             data-mdb-target="#sidebarMenu"
             aria-controls="sidebarMenu"
             aria-expanded="false"
             aria-label="Toggle navigation"
             >
       <i className="fas fa-bars"></i>
      </button>
      
      <a className="navbar-brand" href="#">
      <img src={ImagesUrl+"/log.png"} style={{height: '40px'}} loading="lazy" />
      </a>
    
      <ul className="navbar-nav ms-auto d-flex flex-row">
      
      <div id="google_translate_element"></div>
      
      
       <li className="nav-item dropdown">
         <a
            className="nav-link me-3 me-lg-0 dropdown-toggle hidden-arrow"
            href="#"
            id="navbarDropdownMenuLink"
            role="button"
            data-mdb-toggle="dropdown"
            aria-expanded="false"
            >
           <i className="fas fa-bell"></i>
           <span className="badge rounded-pill badge-notification bg-danger" >1</span  >
         </a>
         <ul
             className="dropdown-menu dropdown-menu-end"
             aria-labelledby="navbarDropdownMenuLink"
             >
           <li><a className="dropdown-item" href="#">New Message</a></li>
          
         </ul>
       </li>
      
       
       <li className="nav-item dropdown">
         <a
            className="nav-link dropdown-toggle hidden-arrow d-flex align-items-center"
            href="#"
            id="navbarDropdownMenuLink"
            role="button"
            data-mdb-toggle="dropdown"
            aria-expanded="false"
            >
           <img
                src={ImagesUrl+"/Male.png"}
                className="rounded-circle"
                height="22"
                alt=""
                loading="lazy"
                />
         </a>
         <ul
             className="dropdown-menu dropdown-menu-end"
             aria-labelledby="navbarDropdownMenuLink"
             >
           <li><a className="dropdown-item" href="/profile">My profile</a></li>
           <li><a className="dropdown-item" href="/referrals">Referrals</a></li>
      
           <li className="dropdown-divider"></li>
           <li><a className="dropdown-item" href={'/?refferer='+window.location.href}>Log Out</a></li>
      
         </ul>
       </li>
      </ul>
      </div>
      </nav>
    )
}

export default Topbar
