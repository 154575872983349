import Cookies from 'js-cookie'
var CryptoJS = require('crypto-js')

export  const ServerUrl = 'https://admin.livetradeix.com/controller';
export  const ImagesUrl = 'https://admin.livetradeix.com/images';
export  const SiteLink = 'https://livetradeix.com';  

/* export  const ServerUrl = 'http://192.168.64.3/React/kennedy/viewlifeenergy/controller';
export  const ImagesUrl = 'http://192.168.64.3/React/kennedy/viewlifeenergy/images';
export  const SiteLink = 'http://localhost:3000'; */   

export const Currency = {
    unicode: '$',
    title: 'USD'
}


export const Code = Cookies.get('vlifeinvcdad')?CryptoJS.AES.decrypt(Cookies.get('vlifeinvcdad'), 'viewlifeenergy').toString(CryptoJS.enc.Utf8):'null'
export const Token = Cookies.get('vlifeinvtkad')?CryptoJS.AES.decrypt(Cookies.get('vlifeinvtkad'), 'viewlifeenergy').toString(CryptoJS.enc.Utf8):'null'
export const Sitename = 'Live Trade Ltd';
export const config = {
    headers: { 'content-type': 'multipart/form-data'}
} 